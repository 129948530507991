import request from '@/utils/request';

// 设备品牌列表
export function DeviceBrandLists(params?: Record<string, any>) {
    return request.post({ url: '/device/device_brand/list', ...params });
}

// 设备品牌详情
export function DeviceBrandDetail(params: Record<string, any>) {
    return request.get({ url: '/device/device_brand/detail', params });
}

// 设备品牌新增
export function DeviceBrandAdd(params: Record<string, any>) {
    return request.post({ url: '/device/device_brand/add', params });
}

// 设备品牌编辑
export function DeviceBrandEdit(params: Record<string, any>) {
    return request.post({ url: '/device/device_brand/edit', params });
}

// 设备品牌删除
export function DeviceBrandDelete(params: Record<string, any>) {
    return request.post({ url: '/device/device_brand/del', params });
}

// 设备品牌
export function DeviceBrandAll(params?: Record<string, any>) {
    const url = `/device/device_brand/all`;
    return request.post({ url, params });
}


